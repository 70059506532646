<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Edit Shift Names",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  mounted(){
    this.getShiftName();
  },
  methods: {
    confirm() {
      Swal.fire({
          title: "Are you sure?",
          text: "All the shifts name that is made under this name will be updated.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#70C213",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Update",
      }).then((result) => {
          if (result.value) {
              this.submitShiftName();
          }
      });
    },
    getShiftName(){
      this.$axios.get('/shift/name/edit/' +  this.$route.params.shiftName_id)
      .then(response => {
        let data = response.data.payload;
        this.shift_name = data.name;
        this.oldValue = data.name;
      }).catch(error => {
          this.handleErrorResponse(error.response, "error");
      }).finally(() => {
          this.processing = false;
      });  
    },

    confirmAction(){
      if(this.oldValue != this.shift_name){
        this.confirm();
      }else{
        this.submitShiftName();
      }
    },
    submitShiftName(){
      this.$v.$touch();
      if (this.$v.$invalid || this.processing) {
        return;
      }
      this.processing = true;
      this.$axios.post('/shift/name/update' , {
          id : this.$route.params.shiftName_id,
          name: this.shift_name
      })
      .then(response => {
          let data = response.data;
          this.triggerSwal(data.message, "success");
          this.$router.push("/shift-names");
          this.processing = false;
      }).catch(error => {
          this.handleErrorResponse(error.response, "error");
      }).finally(() => {
          this.processing = false;
      });  
    }
  },
  data() {
    return {
      processing: false,
      title: "Edit Shift Names",
      shift_name: null,
      oldValue: null,
    };
  },
  validations: {
    shift_name: { required },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="card p-4">
        <b-form>
          <div class="row">
            <div class="col-md-6">
              <div class="col-md-12 mb-3">
                <div class="d-flex align-items-center gap-2">
                  <div class="icon-wrapper mt-3 pt-1">
                    <i class="ui uil-clock-ten f-18 text-muted"></i>
                  </div>
                  <div class="form-field__wrapper w-100">
                    <label>Name</label>
                    <b-form-input
                      id="input-2"
                      class="bg-white"
                      v-model="shift_name"
                      placeholder="Enter Name Like Breakfast, Lunch, Dinner"
                      :class="{ 'is-invalid': $v.shift_name.$error }"
                      required
                    ></b-form-input>
                    <div v-if="$v.shift_name.$error" class="invalid-feedback">
                      <span v-if="!$v.shift_name.required"
                        >Name is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3 text-end">
            <SubmitButton
              :processing="processing"
              text="Update"
              @clicked="confirmAction"
            />
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>
